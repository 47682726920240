import * as Yup from 'yup';
import { getDateFormattedForSchema } from './formHelpers';

// NOTE: all validators prefixed with "optional" should imply that field is not required, but if filled in then it should perform the following validation

export const dateValidation = Yup.date()
  .required('This field is required')
  .min('01/01/1903', 'Year can only be as early as 1903')
  .max(new Date(), 'Date cannot be in the future');

export const dateValidationWithOffset = (pastOffset = 0, futureOffset = 0) =>
  Yup.date()
    .required('This field is required')
    .min(getDateFormattedForSchema(pastOffset), `Must be earlier than ${-pastOffset} days ago`)
    .max(getDateFormattedForSchema(futureOffset), `Must be within ${futureOffset} calendar days`);

export const phoneValidation = Yup.string()
  .required('This field is required')
  .matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}/, 'Phone number must follow this format: 123-555-6789');

export const optionalCLIANumberValidation = Yup.string().test(
  'conditional-validation',
  'Must contain only letters and numbers and be exactly 10 characters',
  (value: any) => {
    if (value && (!value.match(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/) || value.length !== 10)) {
      return false;
    }

    return true;
  },
);

export const textNumericValidation = Yup.string().matches(/^[0-9]*$/, 'Must contain only numbers');

export const providerGroupPreferredPPNValidation = Yup.string().matches(
  /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/,
  'Must contain only letters and numbers',
);

export const optionalTextNumericValidation = (numOfDigits: number) =>
  Yup.string().test(
    'conditional-validation',
    `Must contain only numbers and be exactly ${numOfDigits} digits`,
    (value: any) => {
      if (value && (!value.match(/^[0-9]*$/) || value.length !== numOfDigits)) {
        return false;
      }

      return true;
    },
  );

const optionalValidatePhoneAndFaxFormat = (value: any) => {
  if (value && !value.match(/[0-9]{3}-[0-9]{3}-[0-9]{4}/)) {
    return false;
  }

  return true;
};

export const optionalPhoneValidation = Yup.string().test(
  'conditional-validation',
  'Must follow this format: 123-555-6789',
  optionalValidatePhoneAndFaxFormat,
);

export const optionalFaxValidation = Yup.string().test(
  'conditional-validation',
  'Must follow this format: 123-555-6789',
  optionalValidatePhoneAndFaxFormat,
);

export const zipValidation = Yup.string()
  .required('This field is required')
  .min(5, 'Please enter a valid 5-digit ZIP code')
  .matches(/^\d+$/, 'Please enter a valid 5-digit ZIP code');

export const optionalZipValidation = Yup.string().test(
  'conditional-validation',
  'Please enter a valid 5-digit ZIP code',
  (value) => {
    if (value && value.length < 5) {
      return false;
    }
    if (value && !value.match(/^\d{5}$/)) {
      return false;
    }

    return true;
  },
);

export const NPIValidation = Yup.string()
  .required('This field is required')
  .matches(/^\d+$/, 'NPI must only consist of numbers')
  .min(10, 'NPI must be exactly 10 digits');

export const optionalNPIValidation = Yup.string()
  .test('conditional-validation', 'NPI must only consist of numbers', (value) => {
    if (value) {
      if (!value.match(/^\d+$/)) {
        return false;
      }
    }
    return true;
  })
  .test('conditional-validation', 'NPI must be exactly 10 digits', (value) => {
    if (value) {
      if (value.length < 10) {
        return false;
      }
    }
    return true;
  });

export const checkboxValidation = (minChecked: number, message: string) => Yup.array().min(minChecked, message);

export const servicesLocationValidation = checkboxValidation(1, 'Please select at least 1 location');

export const hasCompletedW9Validation = checkboxValidation(1, 'Please check the field');

export const taxIDValidation = Yup.string()
  .required('This field is required')
  .matches(/[0-9]{3}-[0-9]{2}-[0-9]{4}/, 'Tax ID number must follow this format: 987-00-1234');
