import { ReactElement, useEffect } from 'react';
import FormWrapper from 'components/FormWrapper';
import 'styles/button.scss';
import { FormTitles } from 'types/FormTypes';
import PageTitle from 'components/PageTitle';
import SuccessPage from 'components/SuccessPage';

const newForm = 'https://commonforms.bcbsnc.com/fraud-and-abuse-report-form';

function FraudAndAbuseReportForm(): ReactElement {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace(newForm);
    }, 3000);
  });

  const renderForm = () => {
    return (
      <div className="form-content-width">
        <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
          This form has moved
        </bc-typography>
        <bc-typography>
          You will be automatically redirected to the new form in 3 seconds. If you are not redirected, please click{' '}
          <a href={newForm} target="_blank" rel="noreferrer">
            here
          </a>
        </bc-typography>
      </div>
    );
  };

  return (
    <FormWrapper>
      <PageTitle title="Fraud and Abuse Report Form" />
      {renderForm()}
      <SuccessPage formName={FormTitles.FraudAndAbuse} />
    </FormWrapper>
  );
}

export default FraudAndAbuseReportForm;
