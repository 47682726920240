import { ReactElement, useEffect } from 'react';
import FormWrapper from 'components/FormWrapper';
import PageTitle from 'components/PageTitle';

function IndividualPractitionerForm(): ReactElement {
  const newForm = 'https://commonforms.bcbsnc.com/individual-practitioner-form';

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(newForm);
    }, 3000);
  });

  const renderForm = () => {
    return (
      <div className="form-content-width">
        <bc-typography variant="h1" size="display-xx-sm" color="primary-900">
          This form has moved
        </bc-typography>
        <bc-typography>
          You will be automatically redirected to the new form in 3 seconds. If you are not redirected, please click{' '}
          <a href={newForm} target="_blank" rel="noreferrer">
            here
          </a>
        </bc-typography>
      </div>
    );
  };

  return (
    <FormWrapper>
      <PageTitle title="Individual Practitioner Enrollment Application" />
      {renderForm()}
    </FormWrapper>
  );
}

export default IndividualPractitionerForm;
