import { Dispatch, SetStateAction } from 'react';
import { fireBcncAnalyticsEvent, getFormStepBackAction, getFormStepContinueAction } from 'utilities/eventHelpers';
import { BcncAnalyticsEventActions } from 'types/BcncAnalyticsTypes';
import { FormTitles } from 'types/FormTypes';
import {
  ProviderAcquisitionStep1Validation,
  ProviderAcquisitionStep2Validation,
  ProviderAcquisitionStep3Validation,
  ProviderAcquisitionStep4Validation,
  ProviderAcquisitionStep5Validation,
} from 'forms/providerAcquisitionForm/schema';
import { HandleNextStepArgsType } from 'types/MultiStepTypes';

export const handlePreviousStep = (
  currentStep: number,
  setCurrentStep: Dispatch<SetStateAction<number>>,
  formName: string,
): void => {
  // if not first step, go back a step and fire bcanalytics event for "back to step ___"
  if (currentStep !== 0) {
    setCurrentStep(currentStep - 1);
    fireBcncAnalyticsEvent(getFormStepBackAction(currentStep), formName);
  }
};

export const handleNextStep = ({ formName, steps }: HandleNextStepArgsType): void => {
  const { currentStep, totalSteps, setCurrentStep } = steps;

  // if first step, fire bcanalytics event for form start
  if (currentStep === 0) {
    fireBcncAnalyticsEvent(BcncAnalyticsEventActions.FormStart, formName);
  }

  // if not first or last step, fire bcanalytics event for "continuing to step ___"
  if (currentStep !== 0 && currentStep !== totalSteps) {
    fireBcncAnalyticsEvent(getFormStepContinueAction(currentStep), formName);
  }

  // if not on last step, proceed to next step
  if (currentStep !== totalSteps) {
    setCurrentStep(currentStep + 1);
  }
};

const getProviderAcquisitionValidation = (currentStep: number) => {
  switch (currentStep) {
    case 1:
      return ProviderAcquisitionStep1Validation;
    case 2:
      return ProviderAcquisitionStep2Validation;
    case 3:
      return ProviderAcquisitionStep3Validation;
    case 4:
      return ProviderAcquisitionStep4Validation;
    case 5:
      return ProviderAcquisitionStep5Validation;
    default:
      return {};
  }
};

export const getStepValidationSchema = (currentStep: number, formTitle: FormTitles): any => {
  switch (formTitle) {
    case FormTitles.ProviderAcquisition:
      return getProviderAcquisitionValidation(currentStep);
    default:
      return {};
  }
};
